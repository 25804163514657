var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cp-head-table',{attrs:{"title":_vm.$t('affiliatesCampaigns.title'),"button-title":_vm.$t('affiliatesCampaigns.button.campaign.add'),"title-icon":"ios-build","button-icon":"ios-add-circle-outline"},on:{"onCreateElem":_vm.toCreateModal}}),(!_vm.isWaiting)?_c('cp-table',{ref:"cpTable",attrs:{"get-data-action":"issuerAffiliateCampaigns/getAffiliateCampaigns","url-params":_vm.urlParams,"fields":_vm.fields,"data-parser":_vm.parseData},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var rowData = ref.rowData;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toEditModal(rowData.item)}}},[_vm._v(" "+_vm._s(rowData.item.name)+" ")])]}},{key:"status",fn:function(ref){
var rowData = ref.rowData;
return [_vm._v(" "+_vm._s(_vm.$t(("affiliatesCampaigns.status." + (rowData.item.status))))+" ")]}},{key:"updatedAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFilter")(rowData.item.updatedAt)))])]}},{key:"createdAt",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFilter")(rowData.item.createdAt)))])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],staticClass:"mr-3",attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":_vm.$t('common.button.edit')},on:{"click":function($event){return _vm.toEditModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-md-create",style:({ color: '#04bec4' })}),_vm._v(" "+_vm._s(_vm.$t('common.button.edit'))+" ")]),_c('b-btn',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(true),expression:"true",modifiers:{"hover":true}}],attrs:{"variant":"default edit-operator-btn btn-xs md-btn-flat","title":_vm.$t('common.button.delete')},on:{"click":function($event){return _vm.openDeleteModal(rowData.item)}}},[_c('i',{staticClass:"ion ion-ios-trash",style:({ color: '#04bec4' })}),_vm._v(" "+_vm._s(_vm.$t('common.button.delete'))+" ")])]}}],null,false,1429737144)}):_vm._e(),_c('cp-delete-modal',{ref:"cpDeleteAlert",attrs:{"item-info":_vm.deleteItem},on:{"delete-item":_vm.removeItem}}),_c('cp-campaign-modal',{ref:"CpCampaignModal",attrs:{"title-data":_vm.titleData,"campaign-data":_vm.campaignData,"affiliates":_vm.affiliates},on:{"onSubmit":_vm.saveChanges}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }