const tdClass = 'align-middle';

export default [
  {
    key: 'name', i18nKey: 'affiliatesCampaigns.label.table.name', tdClass, sortable: true,
  },
  {
    key: 'externalId', i18nKey: 'affiliatesCampaigns.label.table.externalId', tdClass, sortable: true,
  },
  {
    key: 'affiliateName', i18nKey: 'affiliatesCampaigns.label.table.affiliateName', tdClass,
  },
  {
    key: 'status', i18nKey: 'affiliatesCampaigns.label.table.status', tdClass, sortable: true,
  },
  {
    key: 'updatedAt', i18nKey: 'affiliatesCampaigns.label.table.updatedAt', tdClass, sortable: true,
  },
  {
    key: 'createdAt', i18nKey: 'affiliatesCampaigns.label.table.createdAt', tdClass, sortable: true,
  },
  {
    key: 'actions', label: ' ', tdClass: `text-nowrap ${tdClass} text-center`,
  },
];
