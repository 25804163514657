<template>
  <cp-general-modal
    ref="issuerCampaign"
    :title="getTitleName"
    :title-icon="`ion ion-${titleData.icon}`"
    :form-group-value="campaignData"
    @onOk="handleSubmit"
  >
    <cp-input
      v-model="model.name"
      name="name"
      :label="$t('affiliatesCampaigns.affiliatesCampaignsModal.input.name')"
    />

    <cp-input
      v-model="model.externalId"
      name="externalId"
      :label="$t('affiliatesCampaigns.affiliatesCampaignsModal.input.externalId')"
    />
    <cp-select
      v-model="model.affiliateId"
      name="affiliateId"
      :label="$t('affiliatesCampaigns.affiliatesCampaignsModal.input.affiliateId')"
      :options="affiliates"
      :disabled="titleData.type === 'Edit'"
    />
    <cp-select
      v-model="model.status"
      name="status"
      :label="$t('affiliatesCampaigns.affiliatesCampaignsModal.input.status')"
      :options="statuses"
    />

    <div
      v-if="titleData.type === 'Edit'"
      class="row mt-2"
    >
      <div class="col text-muted">
        <label class="form-label">
          {{ $t('affiliatesCampaigns.affiliatesCampaignsModal.label.createdAt') }}
        </label> <br>
        <span>
          {{ model.createdAt | dateFilter }}
        </span>
      </div>
      <div class="col text-muted">
        <label class="form-label">
          {{ $t('affiliatesCampaigns.affiliatesCampaignsModal.label.updatedAt') }}
        </label>  <br>
        <span>
          {{ model.updatedAt | dateFilter }}
        </span>
      </div>
    </div>
  </cp-general-modal>
</template>

<script>
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'IssuerAffiliateCampaignsModal',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },

  props: {
    titleData: {
      type: Object,
      default: () => {},
    },
    campaignData: {
      type: Object,
      default: () => {},
    },

    affiliates: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      model: {},
      statuses: [
        { value: 'planned', text: this.$t('affiliatesCampaigns.status.planned') },
        { value: 'active', text: this.$t('affiliatesCampaigns.status.active') },
        { value: 'finished', text: this.$t('affiliatesCampaigns.status.finished') },
      ],
    };
  },

  computed: {
    getTitleName() {
      return this.$t('affiliatesCampaigns.affiliatesCampaignsModal.title', [this.titleData.label]);
    },
  },

  watch: {
    campaignData: {
      handler(val) {
        this.model = Object.assign({}, val);
      },
    },
  },

  methods: {
    handleSubmit() {
      const action = this.model.id ? 'update' : 'create';
      this.$emit('onSubmit', { action, campaign: this.model });
    },
  },
};
</script>
