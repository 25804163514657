<template>
  <div>
    <cp-head-table
      :title="$t('affiliatesCampaigns.title')"
      :button-title="$t('affiliatesCampaigns.button.campaign.add')"
      title-icon="ios-build"
      button-icon="ios-add-circle-outline"
      @onCreateElem="toCreateModal"
    />
    <cp-table
      v-if="!isWaiting"
      ref="cpTable"
      get-data-action="issuerAffiliateCampaigns/getAffiliateCampaigns"
      :url-params="urlParams"
      :fields="fields"
      :data-parser="parseData"
    >
      <template
        slot="name"
        slot-scope="{ rowData }"
      >
        <a
          href="#"
          @click.prevent="toEditModal(rowData.item)"
        >
          {{ rowData.item.name }}
        </a>
      </template>

      <template
        slot="status"
        slot-scope="{ rowData }"
      >
        {{ $t(`affiliatesCampaigns.status.${rowData.item.status}`) }}
      </template>

      <template
        slot="updatedAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.updatedAt | dateFilter }}</span>
      </template>

      <template
        slot="createdAt"
        slot-scope="{ rowData }"
      >
        <span>{{ rowData.item.createdAt | dateFilter }}</span>
      </template>

      <template
        slot="actions"
        slot-scope="{ rowData }"
      >
        <b-btn
          v-b-tooltip.hover="true"
          class="mr-3"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.edit')"
          @click="toEditModal(rowData.item)"
        >
          <i
            class="ion ion-md-create"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.edit') }}
        </b-btn>
        <b-btn
          v-b-tooltip.hover="true"
          variant="default edit-operator-btn btn-xs md-btn-flat"
          :title="$t('common.button.delete')"
          @click="openDeleteModal(rowData.item)"
        >
          <i
            class="ion ion-ios-trash"
            :style="{ color: '#04bec4' }"
          />
          {{ $t('common.button.delete') }}
        </b-btn>
      </template>
    </cp-table>

    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />

    <cp-campaign-modal
      ref="CpCampaignModal"
      :title-data="titleData"
      :campaign-data="campaignData"
      :affiliates="affiliates"
      @onSubmit="saveChanges"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CpTable from '~/components/shared/cp-table';
import CpHeadTable from '~/components/common/head';
import CpDeleteModal from '~/components/common/table/deleteModal';
import CpCampaignModal from './issuer-aff-campaign-modal';

import fields from './fields';

export default {
  name: 'Campaigns',
  metaInfo: {
    title: 'Campaigns',
  },

  components: {
    CpTable,
    CpHeadTable,
    CpCampaignModal,
    CpDeleteModal,
  },

  data() {
    return {
      statuses: {
        active: { title: 'Active', color: 'success' },
        planned: { title: 'Planned', color: 'warning' },
        finished: { title: 'Finished', color: 'danger' },
      },
      fields,
      affiliates: [],
      campaignData: {},
      deleteItem: null,
      titleData: {},
      isWaiting: true, // wait for affiliates list
      urlParams: { issuerId: this.$route.params.idIssuer },
    };
  },

  created() {
    this.getIssuerAffiliates({ params: this.urlParams }).then(({ data: { data } }) => {
      this.affiliates = data.map(item => ({
        text: item.name,
        value: item.id,
      }));
      this.isWaiting = false;
    });
  },

  methods: {

    ...mapActions('issuerAffiliateCampaigns', [
      'create',
      'getById',
      'update',
      'delete',
    ]),

    ...mapActions('issuerAffiliates', ['getIssuerAffiliates']),

    parseData(data) {
      // TODO fix id issue in api
      return data.map((item) => {
        const affiliate = this.affiliates.find(aff => aff.value === item.affiliateId);
        const campaign = { ...item };
        campaign.affiliateName = affiliate && affiliate.text;
        return campaign;
      });
    },

    hideModal() {
      this.$refs.CpCampaignModal.$refs.issuerCampaign.hide();
    },

    showModal() {
      this.$refs.CpCampaignModal.$refs.issuerCampaign.show();
    },

    openDeleteModal(item) {
      this.deleteItem = item;
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },

    toCreateModal() {
      this.titleData = {
        type: 'Add',
        label: this.$t('common.button.add'),
        icon: 'ios-add-circle-outline',
      };

      this.campaignData = {};
      this.showModal();
    },

    toEditModal({ id }) {
      this.titleData = {
        type: 'Edit',
        label: this.$t('common.button.edit'),
        icon: 'md-create',
      };

      this.getById({
        issuerId: this.$route.params.idIssuer,
        campaignId: id,
      }).then((data) => {
        this.campaignData = data;
        this.showModal();
      });
    },

    removeItem() {
      const { id } = this.deleteItem;
      this.delete({
        issuerId: this.$route.params.idIssuer,
        campaignId: id,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
        setTimeout(() => { this.deleteItem = null; }, 1000);
      });
    },

    saveChanges({ action, campaign }) {
      this[action]({
        issuerId: this.$route.params.idIssuer,
        campaign,
      }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.hideModal();
      });
    },
  },
};
</script>
